.wrapper-recruitment-registration-detail {
  font-family: 'Noto Sans JP', 'ＭＳ Ｐゴシック', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka,
    'MS PGothic', sans-serif;
  font-size: 0.8em;

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
  }
  .text-footer {
    font-weight: bold;
  }
  .block-right {
    .button-upload {
      border: 1px solid #0e5a8d;
      border-radius: 5px;
      color: #ffffff;
      background-color: #0e5a8d;
      text-align: center;
      padding: 4px 0px;
      width: 30%;
    }
    .button-update {
      border: 1px solid #0e5a8d;
      border-radius: 5px;
      color: #ffffff;
      background-color: #0e5a8d;
      text-align: center;
      padding: 4px 0px;
      width: 7%;
      margin: 5px;
    }
    .button-upload-disable {
      border-color: rgb(170, 170, 170) !important;
      background-color: rgb(170, 170, 170)!important;
    }
  }
  .detail-cancel-table-wrappers {
    margin-bottom: 20px;
    width: 100%;
    // text-align: center;
    border: #0e5a8d 2px solid;
    font-weight: bold;
    font-size: 1.2em;
    background: #ffffff;
    color: #0e5a8d;
    position: relative;
    padding: 15px 15px 0px;
    .block-item {
      .block-left {
        width: 90%;
        text-align: left;
        select {
          width: 65%;
          border: 1px solid #0e5a8d;
          border-radius: 5px;
          height: 30px;
        }
        label {
          width: 25%;
          background-color: #929291;
          color : #ffffff;
          height: 30px;
          line-height: 30px;
          margin-right: 10px;
          text-align: center;
        }
        input {
          width: 65%;
          border: 1px solid #0e5a8d;
          height: 30px;
          outline: none;
        }
      }
      .block-right {
        width: 50%;
        text-align: left;
        label {
          margin-right: 10px;
        }
        span {
          margin-right: 15px;
        }
        .title {
          margin-right: 40px;
        }
        .block-btn {
          display: inline-block;
          padding: 4px 12px;
          margin-right: 25px;
          border: 1px solid #0e5a8d;
          border-radius: 5px;
        }
        .block-btn-gray {
          background-color: #ccc8c8;
        }
        .block-btn-yellow {
          background-color: #ecd358;
        }

      }

      .title-file {
        border: 1px solid #0e5a8d;
        border-radius: 5px;
        color: #ffffff;
        background-color: #0e5a8d;
        text-align: center;
        padding: 4px 0px;
      }
      input[type='checkbox'] {
        width: initial;
        height: initial;
      }
      .title-file-custom {
        width: 23%;
      }
    }
    .title-absolute {
      position: absolute;
      color: #0e5a8d;
      background: white;
      padding-top: 0px;
      height: 20px;
      margin-top: -33px;
      margin-left: 12px;
      padding-left: 10px;
      padding-right: 8px;
      font-size: 20px;
    }
  }

  .footer-wrapper-main.d-flex.align-items-center.justify-content-center{
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
  }
}
