.detail-registration-contract {
  .title-absolute {
    position: absolute;
    color: #0e5a8d;
    background: white;
    padding-top: 0px;
    height: 20px;
    margin-top: -33px;
    margin-left: 12px;
    padding-left: 10px;
    padding-right: 8px;
    font-size: 20px;
  }
  .detail-basic-table-wrapper {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    border: #0e5a8d 2px solid;
    font-weight: bold;
    font-size: 1.2em;
    background: #ffffff;
    color: #0e5a8d;
    position: relative;
    padding: 15px 15px 0;
    .disabled-text {
      color: rgb(170, 170, 170)!important;
    }
    .d-contents {
      display: contents;
      padding-left: 15px;
    }
    .block-list-check {
      padding: 6px;
      margin-bottom: 6px;
      border: 1px solid #0e5a8d;
      .list-check-item {
        overflow-y: auto;
        max-height: 200px;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 60px;
        }
        &::-webkit-scrollbar-thumb {
          background: #585858;
          border-radius: 60px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: grey;
        }
        .item {
          padding: 5px 10px;
          display: flex;
          align-items: center;
          background-color: #ffffff;
          border: 1px solid #585858;
          margin: 5px 0px;
          cursor: pointer;
          label {
            margin-bottom: 0px;
            margin-left: 15px;
            cursor: pointer;
          }
          span {
            margin-bottom: 0px;
            cursor: pointer;
          }
          &:first-child {
            margin-top: 0px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
          &-active {
            background-color: #929291;
            color:#ffffff;
          }
        }
      }
      .item-all {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        margin: 4px 0px;
        border: 1px solid #585858;
        label {
          margin-bottom: 0px;
          margin-left: 15px;
        }
      }
    }
  }
  .input-contract {
    width: 100%;
    height: 30px;
    border: 1px solid #0e5a8d;
    outline: none;
  }
  .input-contract-disabled {
    color: rgb(170, 170, 170)!important;
    border-color: rgb(170, 170, 170) !important;
  }

  .label-contract {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    width: 100%;
    background-color: rgb(56, 93, 138);
    color: white;
  }
  .block-date-custom {
    input {
      border: 1px solid #0e5a8d;
      height: 30px;
      outline: none;
    }
  }
  .block-date-maintenance {
    input {
      width: 100%;
    }
  }
  .button-contract {
    width: 100%;
    background-color: #0e5a8d;
    color: white;
    letter-spacing: 0.1rem;
    border-radius: 4px;
    font-size: 14px;
    padding: 3px 0px;
  }
  .button-contract-disabled {
    border-color: rgb(170, 170, 170) !important;
    background-color: rgb(170, 170, 170) !important;
  }
  .button-submit-contract {
    border: 1px solid #0e5a8d;
    border-radius: 5px;
    height: 30px;
    color: #ffffff;
    background-color: #0e5a8d;
    text-align: center;
    width: 200px;
    outline: none;
  }
  .button-disable-contract {
    background-color: #dddddd !important;
    color: rgb(170, 170, 170)!important;
    border-color: rgb(170, 170, 170) !important;
  }
  .select-item {
    height: 100%;
    width: 100%;
    border: 1px solid #0e5a8d;
    outline: none;
  }
}
