.table-info-company {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-bottom: 10px;
  border: #0e5a8d 2px solid;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 14px;

  label {
    margin-bottom: 0px;
  }

  .btn-submit {
    border: #0e5a8d 2px solid;
    padding: 5px 12px;
    border-radius: 4px;
    letter-spacing: 0.1rem;
    font-weight: bold;
    background-color: #387ca9;
    transition: 0.5s;
    color: #ffffff;
    width: 100px;
    margin: 10px auto;

    &:hover {
      background-color: #ffffff;
      text-decoration: none;
      color: #0e5a8d;
    }
  }

  .form-btn-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .btn-customize-modal {
      border: #0e5a8d 2px solid;
      padding: 5px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      width: auto;

      &:hover {
        background-color: #ffffff;
        text-decoration: none;
        color: #0e5a8d;
      }
    }

  }

  .item-table {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    background-color: #d3d4cd;

    .title {
      width: 40%;
      padding: 10px;
    }

    .content {
      width: 60%;
      border-left: 1px solid #dee2e6;
      padding: 10px;
      background-color: #fff;

      .select-color {
        width: 200px;
        height: 40px;
      }

      .form-customize {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        label {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  table {
    margin-bottom: 0px;

    thead {
      background-color: #387ca9;

      th {
        border: 1px solid #000000;
      }
    }

    tbody {
      .td__item {
        width: 305px;
      }
      .td__check {
        width: 50%;
      }

      tr.tomorrow_info {
        display: none;
      }

      td {
        border: 1px solid #000000;
        vertical-align: middle;

        .btn-customize {
          width: 100%;
        }

        .input-check {
          margin-right: 20px;

          label {
            margin-left: 5px;
          }
        }

        &:first-child {
          background-color: #dee2e6;
          width: 18%;
        }
      }
    }
  }
}

.btn-submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
