@import 'src/assets/scss/variable';
@import 'src/assets/mixins/index';

.wrapper-table-process {
  font-family: "Noto Sans JP", "ＭＳ Ｐゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "MS PGothic", sans-serif;
  table {
    tbody {
      color: $white;

      tr:nth-child(odd) {
        th {
          background-color: #828282;
        }

        td {
          background: $light-brown;
        }
      }

      tr:nth-child(even) {
        th {
          background-color: $dark-brown;
        }

        td {
          background: $white;
        }
      }

      font-size: calculateRem(14px);
    }

    td {
      border: 1px solid #ccc;
      //text-align: center;
      vertical-align: middle;
    }

    th {
      border: 1px solid #ccc;
      //text-align: center;
      vertical-align: middle;
      font-weight: 100;
    }

    .table-process-col {
      //width: calculateRem(350px);
      width: 100%;
    }

    .table-process-right {
      width: calculateRem(300px);
    }

    .wrapper-id {
      width: 50px;
    }
  }

  .table-model-service {
    border-collapse: collapse;
    table-layout: fixed;

    td {
      min-width: 278px;
    }

    tbody {
      display: block;
      height: 400px;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}


.group-button-wrapper {
  .process-button {
    width: calculateRem(90px);
  }
}

.form-btn-setting {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 5px;

  button {
    border: #0e5a8d 2px solid;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: #387ca9;
    letter-spacing: 0.1rem;
    transition: 0.5s;
    color: #ffffff;
    cursor: pointer;
    min-width: 100px;

    &:hover {
      background-color: #ffffff;
      color: #387ca9;
    }

    &:disabled {
      cursor: not-allowed;
    }

  }
}

.table-anken-master {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  td {
    min-width: 278px;
  }

  th {
    min-width: 278px;
  }

  thead {
    tr {
      display: block;
      position: relative;
    }
  }

  tbody {
    display: block;
    height: 400px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.table-modal-user {
  thead {
    * {
      border: #0e5a8d 2px solid;
    }
    tr {
      th {
        text-align: center;
        border: #0e5a8d 2px solid;
      }

      th:nth-child(1) {
        width: 10%;
      }
    }
  }

  tbody {
    * {
      border: #0e5a8d 2px solid;
    }
    tr {
      td{
        text-align: center
      }
      td:nth-child(1) {
        width: 10%;

        input[type=checkbox] {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}