.form-body-add-service {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: #0e5a8d 2px solid;
  border-radius: 4px;
  margin-bottom: 10px;

  .form-get-code-add-product {
    display: flex;
    // align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;

    .group-input {
      width: 50%;
    }

    button {
      border: #0e5a8d 2px solid;
      padding: 5px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      cursor: pointer;
      margin-left: 10px;
      height: 34px;
      min-width: 100px;


      &:hover {
        background-color: #ffffff;
        color: #387ca9;
      }
    }
  }

  .form-tree {
    .title-project {
      min-width: 100px;
      text-align: center;
    }

    ul {
      position: relative;
      list-style-type: none;
      margin-left: 50px;
      margin-top: 10px;
      padding-top: 10px;

      &:last-child {
        margin-bottom: 30px;
      }

      &::before {
        content: '';
        display: block;
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 2px solid #0e5a8d;
      }

      .disable-item {
        cursor: not-allowed;
        background-color: #ffffff;
        color: #0e5a8d;
      }
    }

    li {
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 31px;
        height: 2px;
        border-top: 2px solid #0e5a8d;
        margin-top: -7px;
        position: absolute;
        top: 1em;
        left: -39px;
        color: red;
      }

      &:last-child::before {
        background: #fff;
        height: auto;
        top: 1em;
        bottom: 0;
        left: -40px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 600;
      position: relative;
      color: #0e5a8d;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;

    }

    button {
      margin-left: 10px;
      border: #0e5a8d 2px solid;
      padding: 2px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background-color: #ffffff;
        text-decoration: none;
        color: #0e5a8d;
      }
    }

    span {
      margin-left: 10px;
      border: #0e5a8d 2px solid;
      padding: 5px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background-color: #ffffff;
        text-decoration: none;
        color: #0e5a8d;
      }
    }

    .container-checkbox input {
      position: absolute;
      display: none;
    }

    /* Create a custom checkbox */
    .container-checkbox .checkmark {
      position: absolute;
      height: 25px;
      width: 25px;
      background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container-checkbox:hover input~.checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-checkbox input:checked~.checkmark {
      background-color: #2196F3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .container-checkbox .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container-checkbox input:checked~.checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container-checkbox .checkmark:after {
      left: 7px;
      top: 1px;
      width: 10px;
      height: 15px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .group-input {
    border: #0e5a8d 2px solid;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-bottom: 10px;

    input {
      height: 30px;
      border: none;
      outline: none;
      // padding: 5px 12px;
      border-left: #0e5a8d 2px solid;
      text-indent: 5px;
    }

    .title {
      height: 30px;
      background-color: #0e5a8d;
      min-width: 120px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .form-btn-setting {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      border: #0e5a8d 2px solid;
      padding: 5px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      letter-spacing: 0.1rem;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background-color: #ffffff;
        color: #387ca9;
      }
    }
  }
}