.setting-tree-view-pri-sco {
  .wrapper-btn-colappse {
    .btn {
      width: 100%;
      background: #e7e7e7;
      border: #0e5a8d 2px solid;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      margin-bottom: 10px;
      color: #0e5a8d;
    }
  }

  .table-search {
    th {
      text-align: center;
      //background-color: #1c2a53;
      color: #fff;
      width: 200px;
      border: 1px solid #fff;
      font-weight: normal;
      padding: 5px;
      font-size: 11px;
    }

    td {
      background-color: #bcd0eb;
      width: 290px;
      border: 1px solid #fff;
      padding: 5px;

      input {
        font-size: 11px;
      }

      font-size: 13px;
    }
  }

  .import-csv {
    margin-left: 10px;
    border: #0e5a8d 2px solid;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: #387ca9 !important;
    transition: 0.5s;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #ffffff;
      text-decoration: none;
      color: #0e5a8d;
    }
  }

  .import-csv-export {
    margin-left: 10px;
    border: #0e5a8d 2px solid;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: orangered !important;
    transition: 0.5s;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #ffffff;
      text-decoration: none;
      color: #0e5a8d;
    }
  }

  .btn-hideshow {
    width: 158px;
    //height: 18px;
    background-color: white;
    border: 1px solid #84b7dc;
    text-align: center;
    padding: 3px;
    cursor: pointer;
  }

  .hideshow2 {
    font-size: 12px;
  }

  .SearchButton {
    // margin-top: 10px;
    padding: 5px;
  }

  .btnS {
    min-width: 200px;
    font-size: 16px;
    text-align: center;
    padding: 2px;
  }

  .btnSubmit {
    background: #8e8e8e;
    color: #ffffff;
    display: inline !important;
    border: 2px solid #7c7c7c !important;
  }

  .btnReset {
    background: #f7f6f6;
    color: #707070;
    display: inline !important;
    border: 2px solid #aaadaa !important;
  }

  .searchbar {
    width: 100%;
    text-align: center;
    //border: #0e5a8d 2px solid;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    // background: #0e5a8d !important;
    color: #555555;
  }

  .icon-searchbar {
    font-size: 24px;
  }
}

.content-view {
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: 0.1rem;
  border: #0e5a8d 2px solid;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 30px;
}

.form-btn-csv-pri-sco {
  display: flex;
  flex-direction: row;
}
