@import 'src/assets/scss/variable';
@import 'src/assets/mixins/index';
.wrapper-edit-user-master {
  width: 1240px;
  margin: 0 auto;
  min-height: 800px;
  font-family: "Noto Sans JP", "ＭＳ Ｐゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "MS PGothic", sans-serif;

  .body {
    min-height: 535px;
  }

  .wrapper-main {
    width: 100%;
    max-width: 1240px;
    // margin-right: auto;
    // margin-left: auto;
  }
  .wrapper-edit-user {
    font-family: "Noto Sans JP", "ＭＳ Ｐゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "MS PGothic", sans-serif;

    .button-succes {
      background: $dark-blue;
      color: $white;
    }

    .button:hover {
      opacity: 0.7;
    }

    .button {
      padding: 10px 30px;
      box-shadow: none;
      font-size: calculateRem(13px);
    }

    .table-user-info {
      background: $white;

      .table-left {
        background: $dark-brown;
        color: $white;
        border: 1px solid $hex;
        font-size: calculateRem(12px);
      }

      .table-right {
        color: black;
        border: 1px solid $hex;
        align-items: center;
        font-size: calculateRem(12px);
      }

      .input-err {
        border: solid 1px red;
      }
    }

    .wrapper-introduce {
      .small {
        font-size: calculateRem(20px);
      }

      .medium {
        font-size: calculateRem(24px);
      }

      .large {
        font-size: calculateRem(30px);
      }
    }

    .group-btn {
      .small {
        font-size: calculateRem(15px);
      }

      .medium {
        font-size: calculateRem(18px);
      }

      .large {
        font-size: calculateRem(21px);
      }

      .button-submit {
        background: $dark-blue;
        color: $white;
        padding: 7px 14px;
        box-shadow: none;
      }

      .button-submit:hover {
        opacity: 0.7;
      }
    }

    .table-user-info {
      .small {
        font-size: calculateRem(15px);
      }

      .medium {
        font-size: calculateRem(18px);
      }

      .large {
        font-size: calculateRem(21px);
      }
    }

    .title {
      .small {
        font-size: calculateRem(17px);
      }

      .medium {
        font-size: calculateRem(19px);
      }

      .large {
        font-size: calculateRem(21px);
      }
    }
  }
}

.user-master-form-screen {
	.user-master-table {
		width: 100%;

		tr {
      height: 40px;

			th, td {
			  padding: 0 5px !important;
			}

			th {
			  width: 25% !important;
        text-align: center;
			}

			th.header-title {
			  text-align: left;
			  font-size: large;
			}

			td {
        border-left: 0;
        border-right: 0;

        label {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          min-width: 50px;
          justify-content: center;
        }

        .btn-generate-password {
          width: 150px;
          height: 30px;
          background-color: darkgray;
          margin-left: 5px;
        }

			  input, select {
          padding: 0 5px;
          width: 100%;
          background-color: white;
          border: 1px solid #75dc5a;
          outline: none;
          height: 100%;
          height: 30px;

          &:disabled {
            background-color: #e6e0e0;
          }
			  }

			  select {
				  cursor: pointer;
			  }

        .btn-upload-staff-img {
          background-color: cornflowerblue;
          height: 25px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .group-email-input {
          display: flex;

          .input {
            &__box {
              width: 30% !important;
            }
          }

          div {
            display: flex;
            align-items: center;
            padding-right: 10px;

            .email-label {
              width: 68px;
              display: flex;
              margin: 0 !important;
              align-items: center;
            }

            .input-email-checkbox {
              height: 16px;

              &--isLock {
                width: 50% !important;
              }
            }
          }

          .btn-resend-confirm-code {
            height: 30px;
            background-color: red;
            color: white;
            display: flex;
            align-items: center;
            margin-left: 5px;
            font-weight: bold;
          }
        }

        .qualification-list {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          &__item-header {
            div {
              background-color: #FDE9D8;
            }
          }

          &__item {
            display: flex;

            div {
              text-align: center;
              margin: 5px;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }

            .qualification {
              flex: 20;
              text-align: center;
              display: flex;

              button {
                height: 30px;
                margin-left: 2px;
                display: flex;
                align-items: center;
                background-color: red;
                color: white;
              }
            }

            .licenseNumber {
              flex: 20;
            }

            .grade {
              flex: 10;
            }

            .validity-period-from {
              flex: 25;

              div {
                margin: 0 !important;
              }
            }

            .validity-period-to {
              flex: 25;

              div {
                margin: 0 !important;
              }
            }
          }
        }

        .btn-add-new-qualification {
          margin-bottom: 10px;
          background-color: #84cc17;
          color: white;
          font-weight: bold;
        }
			}

			td.inline {
			  display: flex;
			  width: 100%;
			  border: 0;

			  input {
          &:last-child {
            margin-right: 0;
          }
			  }
			}
		}
	}

  .btn-submit-staff {
    margin: 20px;
    width: 100px;
    background-color: #71c356;
    color: white;
    font-size: 14px;
    padding: 3px;
    font-weight: 500;
    outline: none;
  }
}
