.form-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: #0e5a8d 2px solid;
  border-radius: 4px;

  .item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    margin-bottom: 10px;

    .btn-email-update {
      border: #0e5a8d 2px solid;
      padding: 0 5px;
      border-radius: 4px;
      background-color: #387ca9;
      color: #ffffff;
      cursor: pointer;
    }

    .form-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      p {
        margin-bottom: 0px;
        font-size: 17px;
      }

      p:last-child {
        margin-left: 10px;
      }
    }

    .form-checkbox-email {
      margin-left: 30px;
      display: flex;
      flex-direction: column;

      .radio-group {
        display: flex;
        flex-direction: row;

        .radio-last-group {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-right: 20px;

          > label {
            margin: 0;
          }
        }
      }
    }

    .form-checkbox {
      margin-left: 30px;
      display: flex;
      flex-direction: row;

      .check-item {
        margin-left: 30px;
      }

      .check-item:first-child {
        margin-left: 0px;
      }
    }

    .form-check-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: 80px;

      li {
        margin-right: 30px;
        min-width: 200px;
      }

      label {
        margin-bottom: 0px;
        margin-left: 10px;
        font-size: 14px;
      }

      .check-item {
        display: flex;
        align-items: center;

        &--pl30 {
          padding-left: 30px;
          min-width: 100px;
        }

        &--list-style {
          list-style: none;
        }
      }

      .check-item:last-child {
        margin-left: 30px;
      }

      &--sub-child {
        margin-left: 115px;
      }
    }
  }

  .form-btn-setting {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &--detail {
      justify-content: center;
      width: 250px;
      margin-bottom: 5px;
    }

    &--min {
      padding: 1px 20px !important;
    }
  }
}

.group-btn-switch-modal {
  display: flex;
  width: 30%;
  margin-bottom: 10px;

  button {
    border: #0e5a8d 2px solid;
    padding: 5px 12px;
    border-radius: 4px;
    height: 100%;
    background-color: #387ca9;
    color: #ffffff;
    cursor: pointer;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
  button.focus {
    background-color: #ffffff;
    color: #387ca9;
  }
}

.hidden-item {
  visibility: hidden;
}

.form-body.form--height-unset.mail-setting {
  overflow-y: hidden;
}

.checkbox-group {
  margin-right: 25px;
  .btn-email-update {
    border: #0e5a8d 2px solid;
    padding: 0 5px;
    border-radius: 4px;
    background-color: #387ca9;
    color: #ffffff;
    cursor: pointer;
  }
}

.btn-email-submit {
  width: 150px;
  align-self: flex-end;
  border: #0e5a8d 2px solid;
  padding: 0 5px;
  border-radius: 4px;
  background-color: #387ca9;
  color: #ffffff;
  cursor: pointer;
}
